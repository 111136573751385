import React, {Fragment} from 'react';
import {Popover, Transition} from '@headlessui/react';
import {MenuIcon, XIcon} from '@heroicons/react/outline';
import AnchorLink from 'react-anchor-link-smooth-scroll'
import am_logo from '../../images/logo.svg';

const navigation = [
    { name: 'Product', href: '#product' },
    { name: 'Features', href: '#features' },
    { name: 'Pricing', href: '#pricing' },
    { name: 'FAQ', href: '#faq' },
]

export default function Navbar() {
    return (
        <div className="fixed w-full z-50">
            <Popover as="header" className="relative">
                <div className="bg-gray-900 py-6 z-50 w-full">
                    <nav
                        className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
                        aria-label="Global"
                    >
                        <div className="flex items-center flex-1">
                            <div className="flex items-center justify-between w-full md:w-auto">
                                <AnchorLink href="#main_screen">
                                    <span className="sr-only">America Sober</span>
                                    <img
                                        className="h-8 w-auto sm:h-10"
                                        src={am_logo}
                                        alt="Logo AmericaSober"
                                    />
                                </AnchorLink>
                                <div className="-mr-2 flex items-center md:hidden">
                                    <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-800 focus:outline-none focus:ring-2 focus-ring-inset focus:ring-white">
                                        <span className="sr-only">Open main menu</span>
                                        <MenuIcon className="h-6 w-6" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="hidden space-x-8 md:flex md:ml-10">
                                {navigation.map((item) => (
                                    <AnchorLink
                                        offset='100'
                                        key={item.name}
                                        href={item.href}
                                        className="text-base font-medium text-white hover:text-gray-300"
                                    >
                                        {item.name}
                                    </AnchorLink>
                                ))}
                            </div>
                        </div>
                        <div className="hidden md:flex md:items-center md:space-x-6">
                            <a href="/admin/dashboard" className="text-base font-medium text-white hover:text-gray-300">
                                Log in
                            </a>
                            <a
                                href="/registration"
                                className="inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700"
                            >
                                Start free trial
                            </a>
                        </div>
                    </nav>
                </div>

                <Transition
                    as={Fragment}
                    enter="duration-150 ease-out"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="duration-100 ease-in"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <Popover.Panel
                        focus
                        className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top md:hidden"
                    >
                        <div className="rounded-lg shadow-md bg-gray-900 ring-1 ring-black ring-opacity-5 overflow-hidden">
                            <div className="px-5 pt-4 flex items-center justify-between">
                                <div>
                                    <img
                                        className="h-8 w-auto"
                                        src={am_logo}
                                        alt="Logo AmericaSober"
                                    />
                                </div>
                                <div className="-mr-2">
                                    <Popover.Button className="bg-gray-900 rounded-md p-2 inline-flex items-center justify-center text-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-400">
                                        <span className="sr-only">Close menu</span>
                                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                    </Popover.Button>
                                </div>
                            </div>
                            <div className="pt-8 pb-6 bg-gray-900">
                                <div className="px-2 space-y-1">
                                    {navigation.map((item) => (
                                        <AnchorLink
                                            offset='80'
                                            key={item.name}
                                            href={item.href}
                                            className="block px-3 py-2 rounded-md text-base font-medium text-white hover:bg-gray-400"
                                        >
                                            {item.name}
                                        </AnchorLink>
                                    ))}
                                </div>
                                <div className="mt-6 px-5">
                                    <a
                                        href="/registration"
                                        className="block text-center w-full py-3 px-4 rounded-md shadow bg-indigo-600 text-white font-medium hover:bg-indigo-700"
                                    >
                                        Start free trial
                                    </a>
                                </div>
                                <div className="mt-6 px-5">
                                    <p className="text-center text-base font-medium text-gray-200">
                                        Existing customer?{' '}
                                        <a href="/admin/dashboard" className="text-white underline">
                                            Login
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Popover.Panel>
                </Transition>
            </Popover>
        </div>
    )
}
